<template>
  <div class="users">
    <Breadcrumb name1="待办单位" name2="单位列表" />
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 用户列表 -->
       <el-table :data="departmentData.departmentList" stripe style="width: 100%" v-loading="loading">
            <el-table-column prop="id" label="#" width="44px"></el-table-column>
            <el-table-column prop="name" label="单位名称"></el-table-column>
            <!-- <el-table-column label="当前状态" width="80px">
              <template v-slot="scope">
                <el-switch v-model="scope.row.status" disabled> </el-switch>
              </template>
            </el-table-column> -->
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="queryInfo.page"
            @size-change="handleSizeChange"
            :page-size="queryInfo.pageSize"
            :page-sizes="[20,30,40,50]"
            layout="total, prev, pager, next, jumper"
            :total="departmentData.total"
          >
          </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { userAddFormRulesMixin } from '@/common/mixin.js'
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Department',
  mixins: [userAddFormRulesMixin],
  data() {
    return {
      loading: true,
      // 获取用户列表的参数对象
      queryInfo: {
        page: 1,
        // 当前每次显示多少条数据
        pageSize: 50
      },
      // 存放用户的数据和数量
      departmentData: {
        departmentList: [],
        total: 0
      }
    }
  },
  components: {
    Breadcrumb
  },
  created() {
    this.getDepartmentList()
  },
  filters: {
  },
  methods: {
    async getDepartmentList() {
      const res = await this.$http.post('department/list', this.queryInfo)
      if (res.data.code !== 200) {
        this.$message.error('获取列表失败!')
      }
      this.loading = false
      this.$message.success('获取列表成功!')
      this.departmentData.departmentList = JSON.parse(JSON.stringify(res.data.data)) // trRes.filter(item => item.status === 2)
      console.log(res.data.data)
      this.departmentData.total = res.data.data.length
    },
    // 监听 pagesize 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page = newSize
      this.getDepartmentList()
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      console.log(newPage)
      console.log(this.queryInfo.pageSize)
      this.queryInfo.page = newPage
      this.getDepartmentList()
    },
    // 更改是否接收短信
    async userMessageStatuChanged(user) {
      const modifyMessageQs = { id: user.id, receive_status: user.receive_status }
      // console.log(modifyMessageQs)
      const { data: res } = await this.$http.post('user/modifyReceiveStatus', modifyMessageQs)
      if (res.code !== 200) {
        // userInfo.mg_state = !userInfo.mg_state
        return this.$message.error('更新用户状态失败!')
      }
      this.getUserList()
      return this.$message.success('更新用户状态成功!')
    }
  }
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}
</style>
