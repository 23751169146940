<template>
  <div class="users">
    <Breadcrumb name1="同办人员" name2="同事列表" />
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 用户列表 -->
       <el-table :data="userData.userList" stripe style="width: 100%">
            <el-table-column type="index" label="#"></el-table-column>
            <el-table-column prop="name" label="姓名" width="120px"></el-table-column>
            <el-table-column prop="phone" label="电话"></el-table-column>
            <el-table-column prop="extra.login_num" label="登陆次数" sortable width="120px" align="center"></el-table-column>
            <el-table-column prop="created_at" label="申请时间" width="200px" sortable></el-table-column>
            <el-table-column label="接收通知" width="80px">
              <template v-slot="scope">
                <el-switch v-model="scope.row.receive_status" :active-value='1' :inactive-value='2' @change="userMessageStatuChanged(scope.row)"> </el-switch>
              </template>
            </el-table-column>
            <!-- <el-table-column label="当前状态" width="80px">
              <template v-slot="scope">
                <el-switch v-model="scope.row.status" disabled> </el-switch>
              </template>
            </el-table-column> -->
          </el-table>
          <!-- 分页 -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="queryInfo.page"
            @size-change="handleSizeChange"
            :page-size="queryInfo.pageSize"
            :page-sizes="[20,30,40,50]"
            layout="total, prev, pager, next, jumper"
            :total="userData.total"
          >
          </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { userAddFormRulesMixin } from '@/common/mixin.js'
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
export default {
  name: 'Companions',
  mixins: [userAddFormRulesMixin],
  data() {
    return {
      // 获取用户列表的参数对象
      queryInfo: {
        page: 1,
        // 当前每次显示多少条数据
        pageSize: 10
      },
      // 存放用户的数据和数量
      userData: {
        userList: [
          // {
          //   // "id": 1, // 用户id
          //   // "name": "yangsl", // 用户名
          //   // "created_at": null, //生成时间
          //   // "updated_at": "2022-09-27 14:22:34", // 更新时间
          //   // "department_id": 1, //单位id
          //   // "openid": "ozPi54oAYP6AMK-xpo8xZx31ti9Y", // 微信openid
          //   // "phone": "13023946608", // 电话号
          //   // "nick_name": "", // 微信昵称
          //   // "avatar_url": "", // 微信头像
          //   // "username": "", // 用户名
          //   // "type": 1, // 用户类型：1:管理员2:普通用户
          //   // "status": 9, // 用户状态：1:待审核2:审核通过9:停用
          //   // "is_default": 2, // 是否是默认用户1:是2否
          //   // "receive_status": 2 // 是否接收通知：是2否
          // }
        ],
        total: 0
      }
    }
  },
  components: {
    Breadcrumb
  },
  created() {
    this.getUserList()
  },
  filters: {
  },
  methods: {
    async getUserList() {
      const res = await this.$http.post('user/collaborators', this.queryInfo)
      const trRes = JSON.parse(JSON.stringify(res.data.data))
      // console.log(trRes)
      // console.log(JSON.parse(JSON.stringify(res.data.data)))
      if (res.data.code !== 200) {
        this.$message.error('获取列表失败!')
      }
      this.userData.userList = JSON.parse(JSON.stringify(res.data.data)) // trRes.filter(item => item.status === 2)
      console.log(this.userData.userList)
      this.userData.total = res.data.options.total
    },
    // 监听 pagesize 改变事件 每页显示的个数
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.queryInfo.page = newSize
      this.getUserList()
    },
    // 监听 页码值 改变的事件 当前页面值
    handleCurrentChange(newPage) {
      console.log(newPage)
      console.log(this.queryInfo.pageSize)
      this.queryInfo.page = newPage
      this.getUserList()
    },
    // 更改是否接收短信
    async userMessageStatuChanged(user) {
      const modifyMessageQs = { id: user.id, receive_status: user.receive_status }
      // console.log(modifyMessageQs)
      const { data: res } = await this.$http.post('user/modifyReceiveStatus', modifyMessageQs)
      if (res.code !== 200) {
        // userInfo.mg_state = !userInfo.mg_state
        return this.$message.error('更新用户状态失败!')
      }
      this.getUserList()
      return this.$message.success('更新用户状态成功!')
    }
  }
}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
}
</style>
